import { createSelector } from 'reselect';
import StarTitle from '@atlassian/jira-common-directory-v2/src/components/star-title-cell';
import {
	CHANGE_OWNER,
	COPY,
	EDIT,
	DELETE,
} from '@atlassian/jira-directory-base/src/model/operation-types';
import { NAME, POPULARITY, FAVOURITED, OWNER } from '../model/constants/sort-field';
import { getLoggedInUserAccountId } from '../state/selectors/app';
import AccessCell from './content/cells/access';
import ActionsCell from './content/cells/actions';
import EditCell from './content/cells/edit';
import FavoriteCell from './content/cells/favourite';
import OwnerCell from './content/cells/owner';
import PopularityCell from './content/cells/popularity';
import TitleCell from './content/cells/title';
import ChangeOwnerModal from './content/change-owner-modal';
import CopyModal from './content/copy-modal';
import DeleteModal from './content/delete-modal';
import EditModal from './content/edit-modal';
import messages from './messages';

export const operationModals = {
	[COPY]: CopyModal,
	[EDIT]: EditModal,
	[DELETE]: DeleteModal,
	[CHANGE_OWNER]: ChangeOwnerModal,
} as const;

export const emptyFilterResultConfiguration = {
	message: messages.emptyFilterResultMessage,
} as const;

export const getTableConfiguration = createSelector(getLoggedInUserAccountId, (accountId) =>
	[
		accountId !== null
			? {
					title: StarTitle,
					Cell: FavoriteCell,
					sortField: FAVOURITED,
					width: 3,
				}
			: undefined,
		{
			title: messages.name,
			Cell: TitleCell,
			sortField: NAME,
			width: accountId ? 25 : 35,
		},
		accountId !== null
			? {
					title: messages.owner,
					Cell: OwnerCell,
					sortField: OWNER,
					width: 19,
				}
			: undefined,
		{
			title: messages.viewers,
			Cell: AccessCell,
			width: accountId ? 19.5 : 25,
		},
		{
			title: messages.editors,
			Cell: EditCell,
			width: accountId ? 19 : 25,
		},
		{
			title: messages.popularity,
			Cell: PopularityCell,
			sortField: POPULARITY,
			width: accountId ? 9 : 15,
		},
		accountId !== null
			? {
					title: messages.moreActions,
					Cell: ActionsCell,
					width: 6,
					dataTestId: 'dashboards-main.content.headerCell.actions.container',
				}
			: undefined,
	].filter(Boolean),
);

export const getTableLabel = () => messages.tableAriaLabel;

export const getResultsAnnouncerMessage = () => messages.filterResultsAnnouncement;
