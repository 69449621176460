import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchFilterAriaSearchDescription: {
		id: 'directories.dashboards-v2.main.content.filter.search-filter-aria-search-description',
		defaultMessage: 'The table will be filtered below as you type into the field',
		description: 'Describes what the access search filter does for screen reader users',
	},
	searchPlaceholder: {
		id: 'directories.dashboards-v2.main.content.filter.search-placeholder',
		defaultMessage: 'Search dashboards',
		description: 'Placeholder to be displayed for an search feild on dashboards page',
	},
	ownerPlaceholder: {
		id: 'directories.dashboards-v2.main.content.filter.owner-placeholder',
		defaultMessage: 'Select owner',
		description: 'Placeholder to be displayed for an owner picker on dashboards page',
	},
	projectPlaceholder: {
		id: 'directories.dashboards-v2.main.content.filter.project-placeholder',
		defaultMessage: 'Select project',
		description: 'Placeholder to be displayed for an project picker on dashboards page',
	},
	groupPlaceholder: {
		id: 'directories.dashboards-v2.main.content.filter.group-placeholder',
		defaultMessage: 'Select group',
		description: 'Placeholder to be displayed for an group picker on dashboards page',
	},
	searchLabel: {
		id: 'directories.dashboards-v2.main.content.filter.search-label',
		defaultMessage: 'Search',
		description: 'Label to be displayed for an search field on dashboards page',
	},
	ownerLabel: {
		id: 'directories.dashboards-v2.main.content.filter.owner-label',
		defaultMessage: 'Owner',
		description: 'Label to be displayed for an owner picker on dashboards page',
	},
	projectLabel: {
		id: 'directories.dashboards-v2.main.content.filter.project-label',
		defaultMessage: 'Project',
		description: 'Label to be displayed for an project picker on dashboards page',
	},
	groupLabel: {
		id: 'directories.dashboards-v2.main.content.filter.group-label',
		defaultMessage: 'Group',
		description: 'Label to be displayed for an group picker on dashboards page',
	},
});
