import React from 'react';
import { lazy } from 'react-loosely-lazy';
import Blanket from '@atlaskit/blanket';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { ChangeOwnerDashboardModule as ChangeDashboardModalType, Props } from './index';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NoLazyAsyncChangeOwnerDashboardModal = lazy<typeof ChangeDashboardModalType>(
	() => import(/* webpackChunkName: "async-change-owner-modal" */ './index'),
);

const AsyncChangeOwnerDashboardModal = (props: Props) => (
	<JSErrorBoundary
		id="configure-owner-modal"
		packageName="dashboard"
		fallback="flag"
		teamName="endeavour"
	>
		<Placeholder name="change-owner-modal" fallback={<Blanket />}>
			<NoLazyAsyncChangeOwnerDashboardModal {...props} />
		</Placeholder>
	</JSErrorBoundary>
);

export default AsyncChangeOwnerDashboardModal;
