import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyAction: {
		id: 'directories.dashboards-v2.main.content.cells.actions.copy-action',
		defaultMessage: 'Copy dashboard',
		description:
			'Label displayed in the dashboards directory, when we click on meatball menu of the dashboard list item. To enable copying of an existing dashboard',
	},
	moveToTrashAction: {
		id: 'directories.dashboards-v2.main.content.cells.actions.move-to-trash-action',
		defaultMessage: 'Move to trash',
		description:
			'Label displayed in the dashboards directory, when we click on meatball menu of the dashboard list item. To enable moving of an active dashboard to trash',
	},
	changeOwner: {
		id: 'directories.dashboards-v2.main.content.cells.actions.change-owner',
		defaultMessage: 'Change owner',
		description:
			'Label displayed in the dashboards directory, when we click on meatball menu of the dashboard list item. To enable changing owner of an existing dashboard which they own',
	},
	renameOrShareAction: {
		id: 'directories.dashboards-v2.main.content.cells.actions.rename-or-share-action',
		defaultMessage: 'Rename or share dashboard',
		description:
			'Label displayed in the dashboards directory, when we click on meatball menu of the dashboard list item. To enable renaming or sharing of an existing dashboard',
	},
	shareAction: {
		id: 'directories.dashboards-v2.main.content.cells.actions.share-action',
		defaultMessage: 'Share dashboard',
		description:
			'Label displayed in the dashboards directory, when we click on meatball menu of the dashboard list item. To enable sharing to default dashboard',
	},
	moreActions: {
		id: 'directories.dashboards-v2.main.content.cells.actions.more-actions',
		defaultMessage: 'More actions for {dashboardName}',
		description: 'Aria-label for meatball menu of the dashboard list item.',
	},
});
