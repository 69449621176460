import { createSelector } from 'reselect';
import type { SortDirection } from '@atlassian/jira-common-constants/src/sort-directions';
import type {
	Filter,
	Project,
	Group,
	Owner,
} from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import type { Query } from '../../../model';
import type { SortField } from '../../../model/constants/sort-field';
import type { State } from '../../types';

export const getQuery = (state: State): Partial<Query> => state.query;

export const getSortField = (state: State): SortField => state.query.sortField;
export const getSortDirection = (state: State): SortDirection => state.query.sortDirection;
export const getPage = (state: State): number => state.query.page;

// @ts-expect-error - TS2322 - Type 'Filter | undefined' is not assignable to type 'Filter'.
export const getFilter = (state: State): Filter => state.query.filter;
export const getSearchQuery = (state: State): string => getFilter(state).text || '';
export const getSelectedProject = (state: State): Project | undefined => getFilter(state).project;
export const getSelectedGroup = (state: State): Group | undefined => getFilter(state).group;
export const getSelectedOwner = (state: State): Owner | undefined => getFilter(state).owner;

export const isFilterApplied = createSelector(
	getSearchQuery,
	getSelectedProject,
	getSelectedGroup,
	getSelectedOwner,
	(queryValue, projectValue, groupValue, ownerValue) =>
		!!queryValue || !!projectValue || !!groupValue || !!ownerValue,
);
