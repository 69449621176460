// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type DeleteDashboardModalType from './index';

const RLLAsyncDeleteDashboardModal = lazyForPaint(
	() => import(/* webpackChunkName: "async-delete-dashboard-modal" */ './index'),
);

const RLLAsyncDeleteDashboardModalContainer = (
	props: JSX.LibraryManagedAttributes<
		typeof DeleteDashboardModalType,
		ComponentProps<typeof DeleteDashboardModalType>
	>,
) => (
	<Placeholder name="delete-dashboard-modal" fallback={<></>}>
		<RLLAsyncDeleteDashboardModal {...props} />
	</Placeholder>
);

export default RLLAsyncDeleteDashboardModalContainer;
