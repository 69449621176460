import { ASC } from '@atlassian/jira-common-constants/src/sort-directions';
import type { Query } from '../index';
import { NAME } from './sort-field';

export const initialQuery: Query = {
	sortField: NAME,
	sortDirection: ASC,
	page: 1,
	filter: {
		text: undefined,
		project: undefined,
		group: undefined,
		owner: undefined,
	},
};
