export const FAVOURITED = 'favourite' as const;
export const NAME = 'name' as const;
export const OWNER = 'owner' as const;
export const POPULARITY = 'popularity' as const;

export type SortField = typeof FAVOURITED | typeof NAME | typeof OWNER | typeof POPULARITY;

export const SORT_FIELDS = {
	[NAME]: 'NAME',
	[OWNER]: 'OWNER',
	[FAVOURITED]: 'IS_FAVOURITE',
	[POPULARITY]: 'FAVOURITE_COUNT',
} as const;
