import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	myOrganizationAccess: {
		id: 'directories.common.components.access-cell.my-organization-access',
		defaultMessage: 'My organization',
		description:
			'Table cell content displayed on directory pages in Access column. It informs users that access to given entity is open - every logged in user can access this entity.',
	},
	publicAccess: {
		id: 'directories.common.components.access-cell.public-access',
		defaultMessage: 'Public',
		description:
			'Table cell content displayed on directory pages in Access column. It informs users that access to given entity is public - everyone (even anonymous users) can access this entity.',
	},
	privateAccess: {
		id: 'directories.common.components.access-cell.private-access',
		defaultMessage: 'Private',
		description:
			'Table cell contents displayed on directory pages in Access column. It informs users that access to given entity is private - only owner of this entity can access it.',
	},
	allProjectRoles: {
		id: 'directories.common.components.access-cell.all-project-roles',
		defaultMessage: 'All roles',
		description:
			'Role information displayed in Access column of directory tables. It is displayed when access to given entity is limited to users having access to given project, but it is not limited by project roles, i.e. users belonging to every role in project can access it.',
	},
});
