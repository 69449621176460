import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import type { Dashboard } from '@atlassian/jira-dashboards-common/src/model';
import type {
	EntityCellProps as GenericEntityCellProps,
	OrderedEntityPage,
	Query as GenericQuery,
} from '@atlassian/jira-directory-base/src/model';
import type {
	CHANGE_OWNER,
	COPY,
	EDIT,
	DELETE,
} from '@atlassian/jira-directory-base/src/model/operation-types';
import type { FavoriteChangeContextType } from '@atlassian/jira-favourite-change-provider/src';
import type { SortField } from './constants/sort-field';

export const ITEMS_PER_PAGE = 25;

export type Operations = typeof COPY | typeof EDIT | typeof DELETE | typeof CHANGE_OWNER;

export type { Filter };

export type Query = GenericQuery<SortField, Filter>;

export type PrefetchedData = {
	dashboards?: OrderedEntityPage<string, Dashboard>;
};

export type StateProps = {
	readonly accountId: string | null;
	readonly baseUrl: string;
	readonly favoriteChangeContext: FavoriteChangeContextType;
	readonly xsrfToken: string | null;
	readonly prefetchedData: PrefetchedData;
	readonly withPageReadyMetrics: boolean;
	readonly onQueryChanged: (arg1: Query) => void;
	readonly onUnmount: () => void;
};

export type Props = StateProps & {
	query: Query | undefined;
};

export type EntityCellProps = GenericEntityCellProps<Operations>;
