import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options';
import { getErrorFromResponse } from './errors';

type LogFn = (error: Error, currentTry: number) => Promise<void>;
export const MAX_NUMBER_OF_TRIES = __SERVER__ ? 1 : 3;

const logResponseError = async (
	logSafeErrorWithoutCustomerData: LogFn,
	response: Response,
	currentTry: number,
) => {
	const error = await getErrorFromResponse(response, 'Directory fetch with retry failed');
	await logSafeErrorWithoutCustomerData(error, currentTry);
};

export const fetchWithRetry = async (
	url: string,
	options: RequestInit,
	logSafeErrorWithoutCustomerData: LogFn,
	currentTry = 1,
): Promise<Response> => {
	try {
		const response = await fetch(url, defaultOptions);
		if (!response.ok) {
			await logResponseError(logSafeErrorWithoutCustomerData, response, currentTry);

			if (currentTry < MAX_NUMBER_OF_TRIES) {
				return fetchWithRetry(url, options, logSafeErrorWithoutCustomerData, currentTry + 1);
			}
		}
		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		await logSafeErrorWithoutCustomerData(error, currentTry);
		if (currentTry < MAX_NUMBER_OF_TRIES) {
			return fetchWithRetry(url, options, logSafeErrorWithoutCustomerData, currentTry + 1);
		}
		throw error;
	}
};
