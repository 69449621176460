import type { ComponentPropsWithRef } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AsyncSelect, type ActionMeta, type ValueType } from '@atlaskit/select';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';

export const AsyncSelectWithAnalytics: React.FC<
	Omit<ComponentPropsWithRef<typeof AsyncSelect>, 'onChange'> & {
		onChange?: (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			newValue: ValueType<any>,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			actionMeta: ActionMeta<any>,
			analyticsEvent: UIAnalyticsEvent,
		) => void;
	}
> = ComponentWithAnalytics('select', { onChange: 'changed' })(AsyncSelect);
