import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	popularity: number;
	intl: IntlShape;
};

function Popularity(props: Props) {
	const {
		popularity,
		intl: { formatMessage },
	} = props;

	return <Container>{formatMessage(messages.popularity, { popularity })}</Container>;
}

export default injectIntl(Popularity);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const Container = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	flexDirection: 'row',
	width: '100%',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	paddingTop: token('space.150'),
	paddingBottom: token('space.150'),
});
