import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model';
import AsyncEditDashboardModal from '@atlassian/jira-edit-dashboard-modal/async';
import { connect } from '@atlassian/jira-react-redux';
import type { State } from '../../../state/types';

export default connect(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(state: State, ownProps: OperationModalProps<Record<any, any>>) => ({
		id: ownProps.entityId,
		onCancel: ownProps.onCancel,
		onSuccess: () => {
			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
			ownProps.onUpdated();
		},
		onInitiateSuccess: () => {
			ownProps.onDialogShown();
		},
		onInitiateFailed: () => {
			ownProps.onDataRetrieveFailed();
		},
	}),
	{},
)(AsyncEditDashboardModal);
