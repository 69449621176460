import { getProjects as getProjectsRest, getProject as getProjectRest } from '../rest';
import { transformProject, transformProjects } from './transformers';
import type { RequestProject, RequestProjects } from './types';

export const requestProjects: RequestProjects = (
	baseUrl,
	filter,
	{ detailed, requireBrowsePermission, maxResults, projectType },
) =>
	getProjectsRest(baseUrl, filter, detailed, requireBrowsePermission, maxResults, projectType).then(
		transformProjects,
	);

export const requestProject: RequestProject = (baseUrl, projectId) =>
	getProjectRest(baseUrl, projectId).then(transformProject);
