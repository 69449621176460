import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	name: {
		id: 'directories.dashboards-v2.main.name',
		defaultMessage: 'Name',
		description: 'Caption for the column heading for displaying dashboard name',
	},
	popularity: {
		id: 'directories.dashboards-v2.main.popularity',
		defaultMessage: 'Starred by',
		description:
			'Caption for the column heading for displaying dashboard popularity (favorites count)',
	},
	owner: {
		id: 'directories.dashboards-v2.main.owner',
		defaultMessage: 'Owner',
		description: 'Caption for the column heading for displaying the info about dashboard owner',
	},
	access: {
		id: 'directories.dashboards-v2.main.access',
		defaultMessage: 'Access',
		description: 'Caption for the column heading for displaying the info about access group',
	},
	viewers: {
		id: 'directories.dashboards-v2.main.viewers',
		defaultMessage: 'Viewers',
		description: 'Caption for the column heading for displaying the info about viewers group',
	},
	editors: {
		id: 'directories.dashboards-v2.main.editors',
		defaultMessage: 'Editors',
		description: 'Caption for the column heading for displaying the info about editors group',
	},
	emptyFilterResultMessage: {
		id: 'directories.dashboards-v2.main.empty-filter-result-message',
		defaultMessage: 'No dashboards were found that match your search',
		description:
			"Message to be displayed when user tried to filter results but their filtering criteria didn't match any",
	},
	tableAriaLabel: {
		id: 'directories.dashboards-v2.main.table-aria-label',
		defaultMessage: 'Dashboards details',
		description:
			'Message to be used as aria-label of dashboards table. The aria-label is announced by screen readers and helps users who use them understand the purpose of table',
	},
	filterResultsAnnouncement: {
		id: 'directories.dashboards-v2.main.filter-results-announcement',
		defaultMessage:
			'{itemCount, plural, =0 {No dashboards were found that match your search} one {{itemCount} dashboard found} other {{itemCount} dashboards found}}',
		description:
			'Message for screen reader users to announce how many dashboards were found after filtering',
	},
	moreActions: {
		id: 'directories.dashboards-v2.main.more-actions',
		defaultMessage: 'More actions',
		description: 'Caption for the column heading for displaying the more actions buttons',
	},
});
