import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { RestUserResponse, RestUsersResponse } from './types';

export const MAX_USERS = 5;

// Endpoints

export const getUserBaseEndpoint = (baseUrl: string): string => `${baseUrl}/rest/api/2/user`;

export const getUserFullEndpoint = (baseUrl: string, accountId = ''): string =>
	`${getUserBaseEndpoint(baseUrl)}?accountId=${encodeURIComponent(accountId)}`;

export const getUsersBaseEndpoint = (baseUrl: string): string =>
	`${baseUrl}/rest/api/2/user/picker`;

export const getUsersFullEndpoint = (baseUrl: string, query = ''): string =>
	`${getUsersBaseEndpoint(baseUrl)}?showAvatar=true&maxResults=${MAX_USERS}&query=${query}`;

// Requests

export const getUser = (baseUrl: string, accountId = ''): Promise<RestUserResponse> =>
	fetchJson(getUserFullEndpoint(baseUrl, accountId));

export const getUsers = (baseUrl: string, query = ''): Promise<RestUsersResponse> =>
	fetchJson(getUsersFullEndpoint(baseUrl, query));
