import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noOptions: {
		id: 'common.components.picker.base.no-options',
		defaultMessage: 'No results',
		description:
			'Message showed in pickers (for users, components, versions etc.) when there is no option that matches the filter.',
	},
	loading: {
		id: 'common.components.picker.base.loading',
		defaultMessage: 'Searching...',
		description:
			'Message showed in pickers (for users, components, versions etc.) when options are being loaded from the server.',
	},
	defaultPlaceholder: {
		id: 'common.components.picker.base.default-placeholder',
		defaultMessage: 'Select...',
		description: 'Message showed in pickers (for users, components, versions etc.) placeholder.',
	},
	clearSelected: {
		id: 'common.components.picker.base.clear-selected',
		defaultMessage: 'Clear selected',
		description:
			'Message showed in pickers (for users, components, versions etc.) when selected value can be cleared.',
	},
});
