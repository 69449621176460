import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	error: {
		id: 'project-picker.error',
		defaultMessage: "We couldn't retrieve projects",
		description:
			'Label for the project picker error. It is shown in case of error fetching projects from the server and displayed inside inline dialog which places automatically above/below or next to the picker',
	},
	placeholder: {
		id: 'project-picker.placeholder',
		defaultMessage: 'Project',
		description:
			'Placeholder for the project picker. Displayed in case it does not have selected value',
	},
	labelText: {
		id: 'project-picker.label-text',
		defaultMessage: 'Project',
		description: 'Label name of project field for screen reader users',
	},
});
