import type { Project, ProjectDetails, ProjectLead, IssueType } from '../model';
import type {
	ProjectsResponse,
	DetailedProjectResponse,
	IssueType as IssueTypeResponse,
	Lead as LeadResponse,
	Project as RestProject,
} from '../rest/types';

const transformLead = (leadResponse?: LeadResponse): ProjectLead | undefined =>
	leadResponse && {
		key: leadResponse.key,
		name: leadResponse.name,
		displayName: leadResponse.displayName,
		avatarUrl: leadResponse.avatarUrls['24x24'],
	};

const transformIssueTypes = (types: IssueTypeResponse[]): IssueType[] =>
	types.map((t) => ({
		id: t.id,
		name: t.name,
		description: t.description,
		iconUrl: t.iconUrl,
		subtask: t.subtask,
	}));

const transformDetails = (restResponse: DetailedProjectResponse | RestProject): ProjectDetails => ({
	id: restResponse.id,
	key: restResponse.key,
	lead: transformLead(restResponse.lead),
	issueTypes: restResponse.issueTypes ? transformIssueTypes(restResponse.issueTypes) : [],
	projectTypeKey: restResponse.projectTypeKey,
	simplified: restResponse.simplified,
});

export const transformProject = (restResponse: DetailedProjectResponse | RestProject): Project =>
	restResponse && {
		value: restResponse.id,
		name: restResponse.name,
		label: restResponse.name,
		avatarUrl: restResponse.avatarUrls['24x24'],
		details: transformDetails(restResponse),
	};

export const transformProjects = (restResponse: ProjectsResponse): Project[] =>
	restResponse && restResponse.values && restResponse.values.map(transformProject);
