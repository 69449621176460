import { connect } from '@atlassian/jira-react-redux';
import { isLoading } from '../../../state/selectors/dashboards';
import type { State } from '../../../state/types';
import Error from './view';

const mapStateToProps = (state: State) => ({
	isLoading: isLoading(state),
});

export default connect(mapStateToProps, {})(Error);
