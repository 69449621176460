import { createStore } from '@atlassian/react-sweet-state';
import actions from './actions';
import type { State } from './types';

const initialState: State = {
	isOpen: false,
};

export default createStore<State, typeof actions>({
	name: 'modals',
	initialState,
	actions,
});
