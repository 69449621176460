import { createSubscriber, createContainer } from '@atlassian/react-sweet-state';
import Store from './store';
import type { State, Actions } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalsContainer = createContainer<State, Actions, Record<any, any>>(Store);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ModalsSubscriber = createSubscriber<State, Actions, boolean, Record<any, any>>(Store, {
	displayName: 'ModalsSubscriber',
	selector: (state) => state.isOpen,
});

export type ModalsSubscriberType = typeof ModalsSubscriber;
