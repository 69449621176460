import flow from 'lodash/flow';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react/src';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../model';
import { getName, getViewUrl } from '../../../../state/selectors/dashboards';
import type { State } from '../../../../state/types';
import Title from './view';

const mapStateToProps = () => (state: State, ownProps: EntityCellProps) => ({
	caption: getName(state, ownProps.id),
	href: getViewUrl(state, ownProps.id),
});

export default flow(
	withFireUiAnalytics({
		onClick: 'dashboard',
	}),
	connect(mapStateToProps, {}),
)(Title);
