// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type EditDashboardModalType from './index';

const AsyncEditDashboardModal = lazyForPaint(
	() => import(/* webpackChunkName: "async-edit-dashboard-modal" */ './index'),
);

const AsyncEditDashboardModalContainer = (
	props: JSX.LibraryManagedAttributes<
		typeof EditDashboardModalType,
		ComponentProps<typeof EditDashboardModalType>
	>,
) => (
	<Placeholder name="edit-dashboard-modal" fallback={<></>}>
		<AsyncEditDashboardModal {...props} />
	</Placeholder>
);

export default AsyncEditDashboardModalContainer;
