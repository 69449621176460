import React, { Component, type ComponentType, type ReactNode } from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import RenderedContext from '../context';

type Props = {
	children: ReactNode;
	performanceMarkKey: string | undefined;
	onMounted: () => void;
};

// eslint-disable-next-line jira/react/no-class-components
class Reporter extends Component<Props> {
	static defaultProps = {
		performanceMarkKey: undefined,
	};

	componentDidMount() {
		this.props.onMounted();
	}

	render() {
		const { performanceMarkKey } = this.props;

		return (
			<>
				{performanceMarkKey && <PerformanceMark metricKey={performanceMarkKey} />}
				{this.props.children}
			</>
		);
	}
}

export function withMountReporting<InnerProps>(Wrapped: ComponentType<InnerProps>): ComponentType<
	InnerProps & {
		performanceMarkKey?: string;
	}
> {
	return (
		props: InnerProps & {
			performanceMarkKey?: string;
		},
	) => {
		const { performanceMarkKey, ...rest } = props;

		return (
			<RenderedContext.Consumer>
				{({ reportMount }) => (
					<Reporter onMounted={reportMount} performanceMarkKey={performanceMarkKey}>
						{/* @ts-expect-error - TS2322 - Type 'Omit<InnerProps & { performanceMarkKey?: string | undefined; }, "performanceMarkKey">' is not assignable to type 'IntrinsicAttributes & InnerProps & { children?: ReactNode; }'. */}
						<Wrapped {...rest} />
					</Reporter>
				)}
			</RenderedContext.Consumer>
		);
	};
}

const MountReporter = withMountReporting(() => <></>);

export default MountReporter;
