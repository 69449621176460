import { combineReducers } from 'redux';
import app from './app';
import dashboards from './dashboards';
import query from './query';

export default combineReducers({
	app,
	dashboards,
	query,
});
