import type { Props } from '@atlassian/jira-copy-dashboard-modal';
import AsyncCopyDashboardModal from '@atlassian/jira-copy-dashboard-modal/async';
import type { OperationModalProps } from '@atlassian/jira-directory-base/src/model';
import { connect } from '@atlassian/jira-react-redux';
import { withRouter, type RouterActionPush } from '@atlassian/jira-router';
import { getBaseUrl } from '../../../state/selectors/app';
import type { State } from '../../../state/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OwnProps = OperationModalProps<Record<any, any>> & {
	push: RouterActionPush;
};

const navigateToDashboard = (baseUrl: string, id: string, push: RouterActionPush) => {
	push(`/jira/dashboards/${id}`);
};

const ConnectedModal = connect(
	(state: State, ownProps: OwnProps): Props => ({
		id: ownProps.entityId,
		onCancel: ownProps.onCancel,
		onSuccess: (_, { id }) => {
			navigateToDashboard(getBaseUrl(state), id, ownProps.push);
			// @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
			ownProps.onUpdated();
		},
		onInitiateSuccess: () => {
			ownProps.onDialogShown();
		},
		onInitiateFailed: () => {
			ownProps.onDataRetrieveFailed();
		},
	}),
	null,
	(stateProps: Props): Props => stateProps,
)(AsyncCopyDashboardModal);

export default withRouter(ConnectedModal);
