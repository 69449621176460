import AccessCell from '@atlassian/jira-common-directory-v2/src/components/access-cell';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../model';
import { getShares } from '../../../../state/selectors/dashboards';
import type { State } from '../../../../state/types';

const mapStateToProps = () => (state: State, ownProps: EntityCellProps) => ({
	shares: getShares(state, ownProps.id),
});

export default connect(mapStateToProps, {})(AccessCell);
