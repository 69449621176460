import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	popularity: {
		id: 'directories.common.components.popularity-cell.popularity',
		defaultMessage: '{popularity, plural, one {{popularity} person} other {{popularity} people}}',

		description: 'Displaying entity popularity (favorites count)',
	},
});
