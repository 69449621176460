import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	error: {
		id: 'group-picker.error',
		defaultMessage: "We couldn't retrieve groups",
		description:
			'Label for the group picker error. It is shown in case of error fetching groups from the server and displayed inside inline dialog which places automatically above/below or next to the picker',
	},
	placeholder: {
		id: 'group-picker.placeholder',
		defaultMessage: 'Group',
		description:
			'Placeholder for the group picker. Displayed in case it does not have selected value',
	},
	labelText: {
		id: 'group-picker.label-text',
		defaultMessage: 'Group',
		description: 'Label name of group field for screen reader users',
	},
});
