import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'directories.dashboards-v2.main.content.error.header',
		defaultMessage: "We couldn't load your dashboards",
		description:
			'Heading for the error state (error screen) which is displayed for users on dashboards page in case dashboards fetching ended up with an error',
	},
	description: {
		id: 'directories.dashboards-v2.main.content.error.description',
		defaultMessage:
			'Give it another try. If you run into more trouble, contact your admin for help.',
		description:
			'Message for the error state (error screen) which is displayed for users on dashboards page in case dashboards fetching ended up with an error',
	},
});
