/** @jsx jsx */
import React, { type ComponentType, type ReactNode, type ReactElement } from 'react';
import { cssMap, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { SpotlightManager } from '@atlaskit/onboarding';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout/src/common/utils/get-will-show-nav4/index.tsx';
import type { EmptyViewProps, ErrorViewProps } from '../../model';
import type { Status } from './common/selectors';

type Props = {
	isOnboardingEnabled: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Header: ComponentType<Record<any, any>> | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Table: ComponentType<Record<any, any>>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Paginator: ComponentType<Record<any, any>>;
	Empty: ComponentType<EmptyViewProps>;
	Error: ComponentType<ErrorViewProps>;
	status: Status;
	renderOnboardingSpotlight: (availableSpotlightTargets: string[]) => ReactElement;
	availableSpotlightTargets: string[];
	onAdd: () => void;
	onRetry: () => void;
};

const styles = cssMap({
	pageContainerNav3: {
		minWidth: '980px',
	},
});

export default function Content(props: Props) {
	const {
		Header,
		Table,
		Paginator,
		Empty,
		Error,
		status,
		onAdd,
		onRetry,
		isOnboardingEnabled,
		renderOnboardingSpotlight,
		availableSpotlightTargets,
	} = props;

	let main: ReactNode = null;
	let spotlight: ReactNode = null;

	switch (status) {
		case 'has-entities': {
			main = (
				<>
					<Table />
					<Paginator />
				</>
			);
			spotlight =
				isOnboardingEnabled &&
				renderOnboardingSpotlight &&
				renderOnboardingSpotlight(availableSpotlightTargets);
			break;
		}
		case 'empty': {
			main = Empty ? <Empty onAdd={onAdd} /> : null;
			break;
		}
		case 'error': {
			main = Error ? <Error onRetry={onRetry} /> : null;
			break;
		}

		default: {
			break;
		}
	}

	if (getWillShowNav4()) {
		return (
			<SpotlightManager>
				<>
					{spotlight}
					{Header ? <Header /> : null}
					{main}
				</>
			</SpotlightManager>
		);
	}

	return (
		<SpotlightManager>
			<div css={styles.pageContainerNav3}>
				{spotlight}
				{Header ? <Header /> : null}
				{main}
			</div>
		</SpotlightManager>
	);
}

Content.defaultProps = {
	isOnboardingEnabled: false,
	renderOnboardingSpotlight: () => null,
	availableSpotlightTargets: [],
	onAdd: noop,
	onRetry: noop,
};
