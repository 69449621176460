import type { Filter } from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import type { Response as ResponseType } from '@atlassian/jira-dashboards-common/src/services/types';
import type { PageRequest } from '@atlassian/jira-shared-types';
import get from '../../../rest/dashboards/get';
import type { Response as RestResponse } from '../../../rest/dashboards/get/types';
import { transformDashboards } from './transformers';

export type Response = ResponseType;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (baseUrl: string, request: PageRequest, filter: Filter): Promise<Response> => {
	const restResponse: RestResponse = await get(baseUrl, request, filter);
	return transformDashboards(baseUrl, restResponse.values, restResponse.total);
};
