import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'directories.dashboards-v2.main.content.filter.clear.label',
		defaultMessage: 'Clear filters',
		description:
			'Label for the button shown on the Dashboards directory page when there is at least one filter applied by user, clicking it resets all the dashboards to their initial state',
	},
});
