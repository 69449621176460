import React, { Component, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type {
	Project,
	Group,
	Owner,
	Filter as FiltersType,
} from '@atlassian/jira-common-directory-v2/src/model/shareable-entities';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import type { Intl } from '@atlassian/jira-shared-types';
import messages from './messages';

type OnSearchChange = (arg1: string, arg2: AnalyticsEvent) => void;
type OnOwnerChange = (owner: Owner, event: AnalyticsEvent) => void;
type OnProjectChange = (project: Project, event: AnalyticsEvent) => void;
type OnGroupChange = (group: Group, event: AnalyticsEvent) => void;
type OnClear = (event: AnalyticsEvent) => void;

export type Props = {
	renderFilterSearch: (arg1: { onChange: OnSearchChange; placeholder: string }) => ReactElement;
	renderOwnerPicker: (arg1: {
		onChange: OnOwnerChange;
		placeholder: string;
		label?: string;
	}) => ReactElement;
	renderProjectPicker: (arg1: {
		onChange: OnProjectChange;
		placeholder: string;
		label?: string;
	}) => ReactElement;
	renderGroupPicker: (arg1: {
		onChange: OnGroupChange;
		placeholder: string;
		label?: string;
	}) => ReactElement;
	renderClear: (arg1: { onClick: OnClear }) => ReactElement;
	initialFilter: FiltersType;
	value: FiltersType;
} & Intl & {
		onChange: (filters: FiltersType, event: AnalyticsEvent) => void;
	};

// eslint-disable-next-line jira/react/no-class-components
export class Filter extends Component<Props> {
	// @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type 'string'.
	onSearchChange: OnSearchChange = (text = undefined, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, text }, analyticsEvent);
	};

	onOwnerChange: OnOwnerChange = (owner, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, owner }, analyticsEvent);
	};

	onProjectChange: OnProjectChange = (project, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, project }, analyticsEvent);
	};

	onGroupChange: OnGroupChange = (group, analyticsEvent) => {
		this.props.onChange({ ...this.props.value, group }, analyticsEvent);
	};

	onClear: OnClear = (analyticsEvent) => {
		this.props.onChange({ ...this.props.initialFilter }, analyticsEvent);
	};

	render() {
		const {
			renderFilterSearch,
			renderOwnerPicker,
			renderProjectPicker,
			renderGroupPicker,
			renderClear,
			intl: { formatMessage },
		} = this.props;

		return (
			<FilterContainer>
				{renderFilterSearch({
					onChange: this.onSearchChange,
					placeholder: formatMessage(messages.searchPlaceholder),
				})}
				<LabelWrapper>
					{renderOwnerPicker({
						onChange: this.onOwnerChange,
						placeholder: formatMessage(messages.ownerPlaceholder),
						label: formatMessage(messages.ownerLabel),
					})}
				</LabelWrapper>
				<LabelWrapper>
					{renderProjectPicker({
						onChange: this.onProjectChange,
						placeholder: formatMessage(messages.projectPlaceholder),
						label: formatMessage(messages.projectLabel),
					})}
				</LabelWrapper>
				<LabelWrapper>
					{renderGroupPicker({
						onChange: this.onGroupChange,
						placeholder: formatMessage(messages.groupPlaceholder),
						label: formatMessage(messages.groupLabel),
					})}
				</LabelWrapper>
				{renderClear({ onClick: this.onClear })}
			</FilterContainer>
		);
	}
}

export default injectIntl(Filter);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterContainer = styled.div({
	display: 'flex',
	alignItems: 'flex-end',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > :not(:last-child)': {
		marginRight: token('space.200', '16px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelWrapper = styled.div({
	marginTop: token('space.negative.250', '-20px'),
});
