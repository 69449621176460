/** @jsx jsx */
import React, { useCallback } from 'react';
import { jsx } from '@compiled/react';
import flow from 'lodash/flow';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/standard-button';
import Tooltip from '@atlaskit/tooltip';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import AsyncCreateDashboardModal from '@atlassian/jira-create-dashboard-modal/src/async.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	ModalsSubscriber,
	ModalsContainer,
} from '@atlassian/jira-modals-controller/src/components.tsx';
import { useRouterActions } from '@atlassian/jira-router';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages';

type Props = {
	onClick: (e: Event, analyticsEvent: UIAnalyticsEvent) => void;
	intl: IntlShape;
};

const CreateButtonWithModal = ({ onClick, intl: { formatMessage } }: Props) => {
	const { push } = useRouterActions();

	const navigateToDashboard = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'id' implicitly has an 'any' type.
		(id) => {
			push(`/jira/dashboards/${id}/edit`);
		},
		[push],
	);

	return (
		<TenantContextSubscriber>
			{({ tenantContext }) =>
				tenantContext.atlassianAccountId ? (
					<ModalsContainer>
						<ModalsSubscriber>
							{(isOpen, { open, close }) => {
								// @ts-expect-error - TS2304 - Cannot find name 'DashboardOperationData'. | TS7031 - Binding element 'id' implicitly has an 'any' type.
								const onSuccess = (_: DashboardOperationData, { id }) => {
									navigateToDashboard(id);
									close();
								};
								return (
									<>
										<Button
											// @ts-expect-error - TS2322 - Type '(e: Event, analyticsEvent: UIAnalyticsEventInterface) => void | Promise<void>' is not assignable to type '(e: MouseEvent<HTMLElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => void'.
											onClick={flow(onClick, open)}
											appearance="primary"
											externalId="directory.dashboards-v2.create-button"
											// eslint-disable-next-line jira/integration/test-id-by-folder-structure
											data-test-id="directory.dashboards-v2.create-button"
										>
											{formatMessage(messages.createDashboard)}
										</Button>
										{isOpen && <AsyncCreateDashboardModal onSuccess={onSuccess} onCancel={close} />}
									</>
								);
							}}
						</ModalsSubscriber>
					</ModalsContainer>
				) : (
					<Tooltip content={formatMessage(messages.notAllowed)}>
						<Button isDisabled onClick={noop} appearance="primary">
							{formatMessage(messages.createDashboard)}
						</Button>
					</Tooltip>
				)
			}
		</TenantContextSubscriber>
	);
};

export const CreateButton = (props: Props) => <CreateButtonWithModal {...props} />;

CreateButton.defaultProps = {
	onClick: noop,
};

export default flow(withFireUiAnalytics({ onClick: 'createDashboard' }), injectIntl)(CreateButton);
