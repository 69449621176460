import React from 'react';
import UserCellDumb, {
	type Props as UserCellDumbProps,
} from '@atlassian/jira-common-directory-v2/src/components/user-cell';
import type { User } from '@atlassian/jira-common-directory-v2/src/model';
import { connect } from '@atlassian/jira-react-redux';
import type { EntityCellProps } from '../../../../model';
import { getOwner } from '../../../../state/selectors/dashboards';
import type { State } from '../../../../state/types';

type StateProps = {
	user: User | null;
};

type OwnProps = EntityCellProps;

const mapStateToProps = (state: State, ownProps: OwnProps): StateProps => ({
	user: getOwner(state, ownProps.id),
});

type EntityCellWrapperType<T> = EntityCellProps & T;
type UserCellProps = EntityCellWrapperType<UserCellDumbProps>;

const UserCell = (props: UserCellProps) => {
	const { user } = props;
	// @ts-expect-error - Property 'onClick' is missing in type '{ user: User | null; }' but required in type 'Props'.
	return <UserCellDumb user={user} />;
};

UserCell.defaultProps = {
	onClick: null,
};

// @ts-expect-error - TS2345 - Argument of type '{ (props: UserCellProps): JSX.Element; defaultProps: { onClick: null; }; }' is not assignable to parameter of type 'Component<EntityCellProps & Props>'.
export default connect(mapStateToProps, {})(UserCell);
