import React from 'react';
import ErrorState from '@atlassian/jira-common-components-error-state';
import { injectIntlV2 as injectIntl, type IntlShapeV2 as IntlShape } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	isLoading: boolean;
	onRetry: () => void;
	intl: IntlShape;
};

function Error(props: Props) {
	const { isLoading, onRetry, intl } = props;
	const { formatMessage } = intl;

	return (
		<ErrorState
			// @ts-expect-error - TS2322: Property 'intl' does not exist on type 'IntrinsicAttributes & Omit<Pick<Props, "onRetry" | "intl" | "description" | "errorImage" | "imageWidth" | "maxImageHeight"> & Partial<Pick<Props, "isLoading" | ... 3 more ... | "retryMessage">> & Partial<...>, "intl"> & { ...; } & { ...; }'.
			intl={intl}
			header={formatMessage(messages.header)}
			description={formatMessage(messages.description)}
			isLoading={isLoading}
			onRetry={onRetry}
		/>
	);
}

Error.defaultProps = {
	isLoading: false,
};

export default injectIntl(Error);
