import type { Group } from '../model';
import type { AllGroupsResponse, UserGroupRest } from '../rest/types';

export const transformAllGroups = (response: AllGroupsResponse): Group[] =>
	response &&
	response.groups &&
	response.groups.map(({ name, groupId }) => ({
		name,
		label: name,
		value: name,
		groupId,
	}));

export const filterUserGroups = (
	groupsRest: UserGroupRest[],
	searchQuery?: string,
): UserGroupRest[] => {
	if (!searchQuery) return groupsRest;

	return groupsRest.filter((group) => group.name.includes(searchQuery || '')); // `|| ''` check is just for Flow
};

export const transformUserGroups = (groupsRest: UserGroupRest[]): Group[] =>
	groupsRest.map(({ name, groupId }) => ({
		name,
		label: name,
		value: name,
		groupId,
	}));
