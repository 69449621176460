// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ComponentProps } from 'react';
import type { Store } from 'redux';
import noop from 'lodash/noop';
import { ViewTracker } from '@atlassian/jira-analytics-web-react/src';
import AppBase from '@atlassian/jira-app-base';
import AppStyle from '@atlassian/jira-common-components-app-style';
import { ReportErrors } from '@atlassian/jira-errors-handling';
import type { Props } from './model';
import { initialQuery } from './model/constants/initial-query';
import { isQueryChanged } from './model/query';
import rootEpic from './ops';
import createStore from './state';
import { setAppProps } from './state/actions/app';
import {
	retrieve as retrieveDashboards,
	retrieved as retrievedDashboards,
} from './state/actions/dashboards';
import { setQuery } from './state/actions/query';
import { getQuery } from './state/selectors/query';
import type { State } from './state/types';
import View from './view';

// eslint-disable-next-line jira/react/no-class-components
export default class Dashboards extends Component<Props> {
	static defaultProps = {
		query: undefined,
		withPageReadyMetrics: true,
		prefetchedData: {},
		onUnmount: noop,
		onQueryChanged: noop,
	};

	constructor(props: Props) {
		super(props);
		this.store = createStore(rootEpic);

		const { dispatch } = this.store;
		const { dashboards } = props.prefetchedData;

		dispatch(setAppProps(props));
		dispatch(setQuery(props.query || initialQuery));

		if (dashboards) {
			dispatch(retrievedDashboards(dashboards));
		}
	}

	componentDidMount() {
		const { dispatch } = this.store;
		const { dashboards } = this.props.prefetchedData;

		if (!dashboards) {
			dispatch(retrieveDashboards());
		}
	}

	componentDidUpdate() {
		this.store.dispatch(setAppProps(this.props));

		// @ts-expect-error - TS2345 - Argument of type 'Partial<Query>' is not assignable to parameter of type 'Query'.
		if (this.props.query && isQueryChanged(this.props.query, getQuery(this.store.getState()))) {
			this.store.dispatch(setQuery(this.props.query));
			this.store.dispatch(retrieveDashboards());
		}
	}

	componentWillUnmount() {
		this.props.onUnmount();
	}

	store: Store<State>;

	render() {
		return (
			<AppBase store={this.store} hasErrorBoundary={false}>
				<ReportErrors id="unhandled" packageName="jiraDashboardsMain" teamName="jira-cosmos">
					<ViewTracker />
					<AppStyle>
						{/* @ts-expect-error - TS2786 - Type 'any' is not assignable to type 'never'. */}
						<View />
					</AppStyle>
				</ReportErrors>
			</AppBase>
		);
	}
}

export type DashboardsProps = JSX.LibraryManagedAttributes<
	typeof Dashboards,
	ComponentProps<typeof Dashboards>
>;
