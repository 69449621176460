import React, { type MouseEvent } from 'react';
import flow from 'lodash/flow';
import Button from '@atlaskit/button';
import { withFireUiAnalytics } from '@atlassian/jira-analytics-web-react/src';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl';
import type { Intl } from '@atlassian/jira-shared-types';
import messages from './messages';

type Props = Intl & {
	onClick: (arg1: MouseEvent<HTMLElement>, arg2: AnalyticsEvent) => void;
};

export function Clear(props: Props) {
	const {
		onClick,
		intl: { formatMessage },
	} = props;

	return (
		<Button appearance="subtle" onClick={onClick}>
			{formatMessage(messages.label)}
		</Button>
	);
}

export default flow(withFireUiAnalytics({ onClick: 'clearFilters' }), injectIntl)(Clear);
