import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createDashboard: {
		id: 'directories.dashboards-v2.create-button.create-dashboard',
		defaultMessage: 'Create dashboard',
		description: 'Text on the button to create a new dashboard.',
	},
	notAllowed: {
		id: 'directories.dashboards-v2.create-button.not-allowed',
		defaultMessage: 'You must be logged in to create dashboards',
		description:
			'Shown in the tooltip on hovering a disabled "create dashboard" button for the logged out users without a permission to create a dashboard',
	},
});
