import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

// initiate

export const INITIATE = 'state.actions.create.INITIATE' as const;

export type InitiateAction = {
	type: typeof INITIATE;
};

export const initiate = (): InitiateAction => ({
	type: INITIATE,
});

// component created

export const ENTITY_CREATED = 'state.actions.create.ENTITY_CREATED' as const;

export type EntityCreatedPayload = {
	id: string;
	name: string;
};

export type EntityCreatedAction = {
	type: typeof ENTITY_CREATED;
	payload: EntityCreatedPayload;
};

export const entityCreated = (payload: EntityCreatedPayload): EntityCreatedAction => ({
	type: ENTITY_CREATED,
	payload,
});

// component create failed

export const ENTITY_CREATE_FAILED = 'state.actions.create.ENTITY_CREATE_FAILED' as const;

export type EntityCreateFailedPayload = {
	error: FetchError;
};

export type EntityCreateFailedAction = {
	type: typeof ENTITY_CREATE_FAILED;
	payload: EntityCreateFailedPayload;
};

export const entityCreateFailed = (
	payload: EntityCreateFailedPayload,
): EntityCreateFailedAction => ({
	type: ENTITY_CREATE_FAILED,
	payload,
});

// cancelled

export const CANCELLED = 'state.actions.create.CANCELLED' as const;

export type CancelledAction = {
	type: typeof CANCELLED;
};

export const cancelled = (): CancelledAction => ({
	type: CANCELLED,
});

// actions

export type Action =
	| InitiateAction
	| EntityCreatedAction
	| EntityCreateFailedAction
	| CancelledAction;
