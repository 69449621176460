import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	error: {
		id: 'user-picker.error',
		defaultMessage: "We couldn't retrieve users",
		description:
			'Error message showed in user picker when there was an error while loading options from server.',
	},
	placeholder: {
		id: 'user-picker.placeholder',
		defaultMessage: 'User',
		description:
			'Placeholder for the user picker. Displayed in case it does not have selected value',
	},
	labelText: {
		id: 'user-picker.label-text',
		defaultMessage: 'User',
		description: 'Label name of user field for screen reader users',
	},
});
