import React, { type ReactNode, Component } from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { ComponentWithAnalytics } from '@atlassian/jira-analytics-web-react/src';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types';
import type { User } from '@atlassian/jira-common-directory-v2/src/model';
import { injectIntl } from '@atlassian/jira-intl';
import type { Intl } from '@atlassian/jira-shared-types';
import messages from './messages';

const DropdownMenuWithAnalytics = ComponentWithAnalytics('dropdownMenu', {
	onOpenChange: 'open',
})(DropdownMenu);

const DropdownItemWithAnalytics = ComponentWithAnalytics('dropdownItem', {
	onClick: 'clicked',
})(DropdownItem);

type Props = {
	isWritable: boolean;
	systemDashboard: boolean;
	defaultOpen: boolean;
} & Intl & {
		showSpinner: boolean;
		loggedInUserAccountId: string;
		caption: string;
		owner: User | null;
		onCopy: () => void;
		onEdit: () => void;
		onChangeOwner: () => void;
		onDelete: () => void;
		onCopyDashboardClick: (arg1: AnalyticsEvent) => void;
		onEditDashboardClick: (arg1: AnalyticsEvent) => void;
		onChangeOwnerClick: (arg1: AnalyticsEvent) => void;
		onDeleteDashboardClick: (arg1: AnalyticsEvent) => void;
	};

type State = {
	isOpen: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
class Actions extends Component<Props, State> {
	static defaultProps = {
		isWritable: false,
		systemDashboard: false,
		defaultOpen: false,
		showSpinner: false,
		owner: null,
		caption: '',
		onChangeOwner: noop,
		onCopy: noop,
		onEdit: noop,
		onDelete: noop,
		onCopyDashboardClick: noop,
		onEditDashboardClick: noop,
		onChangeOwnerClick: noop,
		onDeleteDashboardClick: noop,
	};

	state = {
		isOpen: this.props.defaultOpen,
	};

	// @ts-expect-error - TS7031 - Binding element 'isOpen' implicitly has an 'any' type.
	onOpenChange = ({ isOpen }) => {
		this.setState(() => ({ isOpen }));
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onCopyDashboardClick = (e: any, analytics: any) => {
		this.props.onCopyDashboardClick(analytics);
		this.props.onCopy();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onEditDashboardClick = (e: any, analytics: any) => {
		this.props.onEditDashboardClick(analytics);
		this.props.onEdit();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChangeOwnerClick = (e: any, analytics: any) => {
		this.props.onChangeOwnerClick(analytics);
		this.props.onChangeOwner();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDeleteDashboardClick = (e: any, analytics: any) => {
		this.props.onDeleteDashboardClick(analytics);
		this.props.onDelete();
	};

	/**
	 * Close the menu with a minimal delay so the inner handlers are able to react on keypress properly.
	 * In its current implementation if we close the menu right away it won't open the focused `href`.
	 * Ideally we have to get right of this and few more methods when the bug linked below is fixed.
	 * @see https://ecosystem.atlassian.net/browse/AK-4467
	 */
	delayedClose() {
		setTimeout(() => this.setState(() => ({ isOpen: false })));
	}

	render() {
		const {
			showSpinner,
			loggedInUserAccountId,
			owner,
			isWritable,
			systemDashboard,
			caption,
			intl: { formatMessage },
		} = this.props;

		const isOwnedByCurrentUser = owner && owner.accountId === loggedInUserAccountId;

		const { isOpen } = this.state;

		const IconBefore = showSpinner ? (
			<SpinnerContainer>
				<Spinner size="small" />
			</SpinnerContainer>
		) : (
			<MoreIcon
				label={formatMessage(messages.moreActions, {
					dashboardName: caption,
				})}
			/>
		);

		// @ts-expect-error - TS7031 - Binding element 'triggerRef' implicitly has an 'any' type.
		const triggerFunction = ({ triggerRef, ...props }) => (
			<Button {...props} appearance="subtle" iconBefore={IconBefore} ref={triggerRef} />
		);

		return (
			<Container>
				<DropdownMenuWithAnalytics
					// @ts-expect-error - Type '{ children: Element; position: string; isOpen: boolean; onOpenChange: ({ isOpen }: { isOpen: any; }) => void; trigger: ({ triggerRef, ...props }: { [x: string]: any; triggerRef: any; }) => Element; }' is not assignable to type 'IntrinsicAttributes & Omit<{ appearance?: "default" | "tall" | undefined; autoFocus?: boolean | undefined; children?: ReactNode; isLoading?: boolean | undefined; ... 11 more ...; label?: string | undefined; }, keyof WithAnalyticsEventsProps>'.
					position="bottom right"
					isOpen={isOpen}
					onOpenChange={this.onOpenChange}
					trigger={triggerFunction}
				>
					<DropdownItemGroup>
						<DropdownItemWithAnalytics onClick={this.onCopyDashboardClick}>
							{formatMessage(messages.copyAction)}
						</DropdownItemWithAnalytics>
						{isWritable === true && (
							<DropdownItemWithAnalytics onClick={this.onEditDashboardClick}>
								{formatMessage(
									systemDashboard ? messages.shareAction : messages.renameOrShareAction,
								)}
							</DropdownItemWithAnalytics>
						)}
						{isOwnedByCurrentUser === true && (
							<DropdownItemWithAnalytics onClick={this.onChangeOwnerClick}>
								{formatMessage(messages.changeOwner)}
							</DropdownItemWithAnalytics>
						)}
						{isOwnedByCurrentUser === true && (
							<DropdownItemWithAnalytics onClick={this.onDeleteDashboardClick}>
								{formatMessage(messages.moveToTrashAction)}
							</DropdownItemWithAnalytics>
						)}
					</DropdownItemGroup>
				</DropdownMenuWithAnalytics>
			</Container>
		);
	}
}

// @ts-expect-error - TS2769 - No overload matches this call.
export default injectIntl(Actions);

const Container = ({ children }: { children: ReactNode }) => (
	<Box paddingBlockStart="space.050" paddingInlineEnd="space.025">
		<Inline alignInline="end">{children}</Inline>
	</Box>
);

const SpinnerContainer = ({ children }: { children: ReactNode }) => (
	<Box xcss={spinnerContainerStyles}>{children}</Box>
);

const spinnerContainerStyles = xcss({
	height: 'space.200',
	width: 'space.300',
});
