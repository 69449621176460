// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder';
import type CopyDashboardModalType from './index';

const AsyncCopyDashboardModal = lazyForPaint(
	() => import(/* webpackChunkName: "async-copy-dashboard-modal" */ './index'),
);

const AsyncCopyDashboardModalContainer = (
	props: JSX.LibraryManagedAttributes<
		typeof CopyDashboardModalType,
		ComponentProps<typeof CopyDashboardModalType>
	>,
) => (
	<Placeholder name="dashboard-modal" fallback={<></>}>
		<AsyncCopyDashboardModal {...props} />
	</Placeholder>
);
export default AsyncCopyDashboardModalContainer;
